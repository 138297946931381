import { Container, Typography, Box, ThemeProvider, createTheme, Paper, Link } from '@mui/material';
import { pink } from "@mui/material/colors"
import { Header } from './Header';

export const AntsoftRedirectPage = () => {

  const theme = createTheme({
    typography: {
      fontFamily: "Nunito, Arial",
    },
    palette: {
      primary: pink,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container component="main" maxWidth="md">
        <Paper
          elevation={2}
          square={false}
          sx={{
            display: "flex",
            padding: "1rem",
            marginTop: "4rem",
            height: "100%",
            flexDirection: "column",
            textAlign: "center",
            backgroundColor: "#FCD9D9"
          }}
        >
            <Typography variant="h4" color="textPrimary" gutterBottom>
              Adobe License Manager has been deprecated.
            </Typography>
            <Typography variant="body1" paragraph>
              We're sorry, but this website is no longer maintained. We moved permanently
              to our new{" "}
              <Link href="https://ucirvine.sharepoint.com/sites/UCI-SoftwareAnt-SPO/SitePages/Adobe.aspx">Antsoft Software</Link>
              {" "} page so please redirect there to handling licensing.
            </Typography>
            <Typography color="textSecondary">
              <Link href="https://www.oit.uci.edu/adobe/">Visit our FAQ</Link>{" "}
               to view more information about the update.
            </Typography>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};
