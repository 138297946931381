import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import { App } from "./components/App"
import { AntsoftRedirectPage } from "./components/AntsoftRedirectPage"

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <AntsoftRedirectPage />
  </React.StrictMode>
)
